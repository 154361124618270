import {headshot, ImageWithLoading} from "./Util.js";
import React from "react";
import * as PropTypes from "prop-types";

function Actor(props) {
    return (
        <div className={props.display_type}>
            <img className="square" src={headshot(props.actor)} alt={props.actor.name}/>
            {props.displayName && <div>{props.actor.name}</div>}
        </div>
    );
}
function ActorButton(props) {
    return <button title={`Select ${props.actor?.name} as one of the actors`} className="thingy"
                   onClick={props.onClick}><ImageWithLoading className="smallimg"
                                                             alt={`A headshot of ${props.actor?.name}`}
                                                             src={headshot(props.actor)}></ImageWithLoading>
        {props.displayName && <div className="inline_title">{props.actor?.name}</div>}
    </button>;
}

ActorButton.propTypes = {
    actor: PropTypes.any,
    onClick: PropTypes.func,
    displayName: PropTypes.bool
};
export default Actor;
export {ActorButton, Actor};
