import React, {useEffect, useState, useRef} from "react";
import {Helmet} from "react-helmet";
import {headshot} from "./Util";
import {ImageWithLoading, poster} from "./Util"; // Import the Spinner component
import {ActorSearch} from "./ActorSearch";
import {MovieSearch} from "./MovieSearch";
import './Spinner.css';
import {SelectedActor} from "./SelectedActor";
import {Actor, ActorButton} from "./Actor";
import Hls from 'hls.js';

function Square({ actor, onSquareClick }) {
  return (
      <>
          { (actor !== null) && <ActorButton className="square" displayName={false} actor={actor} /> }
          { (actor == null) && <ActorButton className="square" displayName={false} onClick={onSquareClick}>{actor}</ActorButton> }
      </>
  );
}

function GridGame(props) {
    const [history, setHistory] = useState([Array(16).fill(null)]);
    const [rank, setRank] = useState([]);
    const [file, setFile] = useState([]);
    const [selectedSquare, setSelectedSquare] = useState(0);
    const currentSquares = history[history.length - 1];

    const getMovies = (async (actor) => {
        if (actor.id) {
            const response = await fetch(`/api/movie_credits/${actor.id}`);
            const json = await response.json();
            return json;
        }
        return [];
    });
    const handleClickSquare = (i) => {
        console.log("Clicked square", i);
        setSelectedSquare(i);
    };
    const handleSelectedActor = (actor) => {
        console.log("Selected actor", actor);
        // set the actor in the selected square
        console.table(currentSquares);
        const newSquares = currentSquares.slice();
        newSquares[selectedSquare] = actor;
        console.table(newSquares);
        // set the new state
        setHistory(history.concat([newSquares]));
    }

    return (

        <div className="App">
            <Helmet>
                <title>Actor Movies</title>
            </Helmet>
            <div className="App-header"><h1>Name an actors movies</h1></div>
            <div id="movieListPanel">
                <div className="board-row">
                <Square actor={currentSquares[0]} onSquareClick={() => handleClickSquare(0)} />
                <Square actor={currentSquares[1]} onSquareClick={() => handleClickSquare(1)} />
                <Square actor={currentSquares[2]} onSquareClick={() => handleClickSquare(2)} />
                <Square actor={currentSquares[3]} onSquareClick={() => handleClickSquare(3)} />
                </div>
                <div className="board-row">
                <Square actor={currentSquares[4]} onSquareClick={() => handleClickSquare(4)} />
                <Square actor={currentSquares[5]} onSquareClick={() => handleClickSquare(5)} />
                <Square actor={currentSquares[6]} onSquareClick={() => handleClickSquare(6)} />
                <Square actor={currentSquares[7]} onSquareClick={() => handleClickSquare(7)} />
                </div>
                <div className="board-row">
                <Square actor={currentSquares[8]} onSquareClick={() => handleClickSquare(8)} />
                <Square actor={currentSquares[9]} onSquareClick={() => handleClickSquare(9)} />
                <Square actor={currentSquares[10]} onSquareClick={() => handleClickSquare(10)} />
                <Square actor={currentSquares[11]} onSquareClick={() => handleClickSquare(11)} />
                </div>
                <div className="board-row">
                <Square actor={currentSquares[12]} onSquareClick={() => handleClickSquare(12)} />
                <Square actor={currentSquares[13]} onSquareClick={() => handleClickSquare(13)} />
                <Square actor={currentSquares[14]} onSquareClick={() => handleClickSquare(14)} />
                <Square actor={currentSquares[15]} onSquareClick={() => handleClickSquare(15)} />
                </div>
            </div>
            <hr/>
            <ActorSearch selectActor={(foo) => { handleSelectedActor(foo) }}/>
        </div>

    );
};
function JPEGStream(props)
{
    const [jpegUrl, setJpegUrl] = useState(null);
    const [actualUrl, setActualUrl] = useState(null);
    const [intervalId, setIntervalId] = useState(null);
    // load the jpeg stream in an image tag, update it every 100ms
    useEffect(() => {
        console.log("useEffect", jpegUrl);
        if (!jpegUrl) return;

        const interval = setInterval(() => {
            console.log("Called!")
            //actualUrl = jpegUrl + "&f=" + Math.random();
            setActualUrl(jpegUrl + "&f=" + Math.random());
        }, 500);
        setIntervalId(interval);
        console.log("starting interval", interval);
        return () => { console.log("Clearing interval", interval); clearInterval(intervalId); }

    }, [jpegUrl]);
    const handleJpegError = (e) => {
        console.log("Error loading jpeg, clearing interval", intervalId);
        clearInterval(intervalId);
        setIntervalId(null);
    };
    return (
        <div>
            <input type="text" onChange={(e) => setJpegUrl(e.target.value)} />
            { actualUrl && <img src={actualUrl} alt="jpeg stream" onError={handleJpegError} /> }
        </div>);
}
function HLSPlayer(props)
{
    const [videoUrl, setVideoUrl] = useState(null);
    const myRef = useRef(null);
    useEffect(() => {
        if (!videoUrl) return;
        var videoSrc = videoUrl;
        if (Hls.isSupported()) {
            var hls = new Hls();
            hls.loadSource(videoSrc);
            hls.attachMedia(myRef.current);
        }
    }, [videoUrl]);

    return (
        <div>
            <input type="text" onChange={(e) => setVideoUrl(e.target.value)} />
            { videoUrl && <video autoPlay ref={myRef} style={{ height: 300 }}></video> }
        </div>);


}
//export default ActorsMoviesGame;
export {HLSPlayer, JPEGStream, GridGame};
