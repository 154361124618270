import React, {useState} from "react";
import {Helmet} from "react-helmet";
import {ActorSearch} from "./ActorSearch";
import {MovieButton} from "./Movie";
import {SelectedActor} from "./SelectedActor";

function PairGame(props) {

    const [actor, setActor] = useState(null);
    const [movies, setMovies] = useState([]);
    const [credits, setCredits] = useState([]);
    const [moviePairs, setMoviePairs] = useState([]);
    const [numSelected, setNumSelected] = useState(0);
    const getMovies = (async (actor) => {
        if (actor.id) {
            const response = await fetch(`/api/movie_credits/${actor.id}`);
            const json = await response.json();
            return json;
        }
        return [];
    });
    const selectActor = (async (actor) => {
        setActor(actor);
        console.log('selected actor');
        console.table(actor);
        setMovies([]);
        let credits = await getMovies(actor);
        setCredits([...credits]);
        // select 5 random pairs of movies
        let pairs = [];
        for (let i = 0; i < 50; i++) {
            let a = credits[Math.floor(Math.random() * credits.length)];
            let b = credits[Math.floor(Math.random() * credits.length)];
            console.log(a);
            console.log(b);
            // only push if the movies are different and have a poster and rating
            if (a.id !== b.id && a.poster_path && b.poster_path && a.rating && b.rating)
                pairs.push([a, b]);
        }
        setMoviePairs(pairs);
        setNumSelected(0)
    });
    const unsetActorA = (async () => {
        setActor(null);
        setMovies([]);
        setCredits([]);
        setMoviePairs([]);
        setNumSelected(0)
    });
    const increaseNumSelected = () => {
        setNumSelected(numSelected + 1);
    };

    return (

        <div className="App">
            <Helmet>
                <title>Movie Pair Game (Higher or Lower)</title>
            </Helmet>
            <div className="App-header"><h1>Movie Pair Game (Higher or Lower)</h1></div>
            <div id="movieListPanel">
                <SelectedActor actorA={actor} onClick={unsetActorA}/>
            </div>
            <hr/>
            {!actor && <ActorSearch selectActor={selectActor}/>}
            {moviePairs.slice(0, numSelected + 1).map((pair, idx) => {
                return <div>{idx}<MovieButton movie={pair[0]} selectMovie={() => {
                    console.log(idx, pair[0].rating, pair[1].rating);
                    if (idx == numSelected) increaseNumSelected();
                }}></MovieButton><MovieButton movie={pair[1]} selectMovie={() => {
                    console.log(idx, pair[1]);
                    if (idx == numSelected) increaseNumSelected();
                }}></MovieButton></div>;
            })}
        </div>

    );
}

export {PairGame};