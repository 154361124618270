import {headshot} from "./Util";
import React from "react";

export function SelectedActor(props) {
    return <div id="selected_actor">
        {props.actorA && (
            <div className="inline_img">
                <img className="medimg" src={headshot(props.actorA)} alt={props.actorA.name}/>
                <div>{props.actorA.name}</div>
                <button title={`Remove ${props.actorA.name} as a selected actor`} className="trashcan"
                        onClick={props.onClick}>
                    ❌
                </button>
            </div>
        )}
    </div>;
}