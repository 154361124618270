import React, {useState} from "react";

const headshot = (actor) => {
    return actor?.headshot ? "https://image.tmdb.org/t/p/w500/" + actor.headshot : "/placeholder_poster.png";
}
const poster = (movie) => {
    //console.log(movie, "https://image.tmdb.org/t/p/w500/" + movie.poster_path);
    return movie.poster_path ? "https://image.tmdb.org/t/p/w500/" + movie.poster_path : "/placeholder_poster.png";
}
const Spinner = () => <div className="spinner"></div>;
const ImageWithLoading = ({ src, alt, ...props }) => {
  const [isLoading, setIsLoading] = useState(true);

  const handleImageLoaded = () => {
    setIsLoading(false);
  };

  return (
    <div>
      {isLoading && <Spinner />} {/* Show spinner while loading */}
      <img
        src={src}
        alt={alt}
        onLoad={handleImageLoaded}
        style={{ display: isLoading ? 'none' : 'block' }} // Hide image while loading
        {...props}
      />
    </div>
  );
};


export {headshot, poster, ImageWithLoading};