import './App.css';
import {BrowserRouter, Link, Outlet, Route, Routes} from "react-router-dom";
import { LiteGraph, LGraphCanvas, LGraph } from "litegraph.js";
import React, {useEffect, useState, useRef} from 'react';
import {Helmet} from "react-helmet";
import * as PropTypes from "prop-types";
import './Spinner.css';
import {CreditsGame} from "./ActorsMoviesGame.js";
import {GridGame as GridGame} from "./GridGame.js";
import {ActorSearch} from "./ActorSearch";
import {headshot, poster} from "./Util"; // Import the Spinner component
import {Actor} from "./Actor.js";
import Movie from "./Movie.js";
import {PairGame} from "./PairGame";



function MovieIntersectionList({ actorA, actorB }) {
    const [movieIds, setMovieIds] = useState([]);

    useEffect(() => {
        async function fetchIntersections() {
            if (!actorA || !actorB) {
                setMovieIds([]);
                return;
            }

            console.log(`Fetching ${actorA.id} ${actorB.id}`);
            try {
                let response = await fetch(`/api/movie_intersect_b/${actorA.id}/${actorB.id}`);

                if (!response.ok) {
                    console.log("Error fetching intersect");
                    return;
                }

                let data = await response.json();
                console.log("got intersection", data);
                setMovieIds(data);
            } catch (error) {
                console.error("Error in API call:", error);
            }
        }

        fetchIntersections();
    }, [actorA, actorB]); // Dependencies: Only refetch when actorA or actorB changes

    return (
        <div id="intersectionPanel">
            <ul className="intersectionList">
                {movieIds.length > 0 ? <></> : <li>No intersection</li>}
                {movieIds.map((movieId, index) => (
                    <li>
                        <Movie key={index} movie={movieId}/>
                    </li>
                ))}
            </ul>
        </div>
    );
}
function SelectedActors(props) {
    return <div id="selected_actors">
        {props.actorA && (
            <div className="inline_img">
                <Actor actor={props.actorA} display_type="selected_actor"/>
                <button title={`Remove ${props.actorA.name} as a selected actor`} className="trashcan" onClick={props.onClick}>
                    ❌
                </button>
            </div>
        )}
        {props.actorB && (
            <div className="inline_img">
                <Actor actor={props.actorB} display_type="selected_actor"/>
                <button title={`Remove ${props.actorB.name} as a selected actor`} className="trashcan" onClick={props.onClick}>
                    ❌
                </button>
            </div>
        )}
    </div>;
}

SelectedActors.propTypes = {
    actorA: PropTypes.any,
    src: PropTypes.any,
    onClick: PropTypes.func,
    actorB: PropTypes.any,
    src1: PropTypes.any,
    onClick1: PropTypes.func
};

// function NodeEditor(props)
// {
//     const lgRef = useRef();
//     const lgCanvas = useRef();
//     useEffect(() => {
//         console.log(LiteGraph);
//
//         //LiteGraph.debug = true;
//         let graph = new LGraph();
//         lgCanvas.current = new LGraphCanvas("#mycanvas", graph, {
//             autoresize: false,
//         });
//         var node_const = LiteGraph.createNode("basic/const");
//         node_const.pos = [200,200];
//         graph.add(node_const);
//         node_const.setValue(4.5);
//
//         var node_watch = LiteGraph.createNode("basic/watch");
//         node_watch.pos = [200,200];
//         graph.add(node_watch);
//
//         node_const.connect(0, node_watch, 0 );
//
//
//
//     }, []);
//
//     return (
//         <div>
//             <h1>Node Editor</h1>
//             <canvas id="mycanvas" ref={lgCanvas} width="800" height="600" />
//         </div>
//     );
// }

function ActorToActor() {

  const [actor_a, setActorA] = useState(null);
  const [actor_b, setActorB] = useState(null);


    const selectActor = (async (actor) => {
        if (!actor_a)
        {
            setActorA(actor);
        }
        else {
            setActorB(actor);
        }
    });
    const unsetActorA = (async () => {
        if (actor_b)
        {
            setActorA(actor_b);
            setActorB(null);
        }
        else
        {
            setActorA(null);
        }
    });

    const unsetActorB = (() => {
        setActorB(null);
    });

  return (

    <div className="App">
        <Helmet>
            <title>Actor to Actor</title>
        </Helmet>
        <div className="App-header"><h1>Actor to Actor</h1></div>
        <SelectedActors actorA={actor_a} onClick={() => unsetActorA()} actorB={actor_b}
                        onClick1={() => unsetActorB()}/>
        <hr/>
        <MovieIntersectionList actorA={actor_a} actorB={actor_b}/>
        <ActorSearch selectActor={selectActor}/>
    </div>

  );
}
//import { Outlet, Link } from "react-router-dom";

const Layout = () => {
  return (
    <>
        {/*<nav><Link to="/">Home</Link><Link to="/creditsgame">Credits</Link><Link to="/pairgame">Pairs</Link><Link to="/gridgame">Grid</Link></nav>*/}
      {/*<nav>*/}
      {/*  <ul>*/}
      {/*    <li>*/}
      {/*      <Link to="/">Home</Link>*/}
      {/*    </li>*/}
      {/*    <li>*/}
      {/*      <Link to="/game2">Game2</Link>*/}
      {/*    </li>*/}
      {/*    <li>*/}
      {/*      <Link to="/contact">Contact</Link>*/}
      {/*    </li>*/}
      {/*  </ul>*/}
      {/*</nav>*/}

      <Outlet />
    </>
  )
};
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<ActorToActor />} />
          <Route path="creditsgame" element={<CreditsGame />} />
          <Route path="pairgame" element={<PairGame />} />
          <Route path="gridgame" element={<GridGame />} />
          {/*<Route path="wordle" element={<Wordle />} />*/}
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
