import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import {ImageWithLoading, poster} from "./Util"; // Import the Spinner component
import {ActorSearch} from "./ActorSearch";

import {MovieSearch} from "./MovieSearch";
import './Spinner.css';
import {SelectedActor} from "./SelectedActor";

function CreditsGame(props) {

    const [actor, setActor] = useState(null);
    const [movies, setMovies] = useState([]);
    const [credits, setCredits] = useState([]);
    const [correct, setCorrect] = useState([]);
    const [incorrect, setIncorrect] = useState([]);
    const getMovies = (async (actor) => {
        if (actor.id) {
            const response = await fetch(`/api/movie_credits/${actor.id}`);
            const json = await response.json();
            return json;
        }
        return [];
    });
    const selectActor = (async (actor) => {
        setActor(actor);
        console.log('selected actor');
        console.table(actor);
        setMovies([]);
        let credits = await getMovies(actor);
        setCredits([...credits]);
    });
    const unsetActorA = (async () => {
        setActor(null);
        setMovies([]);
        setCredits([]);
    });
    useEffect(() => {
        setCorrect(movies.filter(isMovieInList))
        setIncorrect(movies.filter((foo) => !isMovieInList(foo)))
    }, [movies]);

    const isMovieInList = (movie) => {
        return !!credits.find((m) => m.id === movie.id);
    }
    return (

        <div className="App">
            <Helmet>
                <title>Actor Movies</title>
            </Helmet>
            <div className="App-header"><h1>Name an actors movies</h1></div>
                    <div id="movieListPanel">
            <SelectedActor actorA={actor} onClick={unsetActorA}/>
                        {actor && <>{correct.length}/{credits.length}</>}
            <ul className="actorlist">
                    {correct.map((a) => {
                        return <li className="inline_img" key={a.id}>
                            <button title={`Select ${a.name} as one of the movies`} className="thingy"><ImageWithLoading
                                className='smallimg' alt={`A movie poster of ${a.name}`} src={poster(a)}></ImageWithLoading>
                            </button>
                            <div  className="trashcan">
                            ✅
                            </div>
                        </li>;
                    })}
                </ul>
                <ul className="actorlist">
                    {incorrect.map((a) => {
                        return <li className="inline_img" key={a.id}>
                            <button title={`Select ${a.name} as one of the movies`} className="thingy"><ImageWithLoading
                                className='smallimg' alt={`A movie poster of ${a.name}`} src={poster(a)}></ImageWithLoading>
                            </button>
                            <div  className="trashcan">
                            ❌
                            </div>
                        </li>;
                    })}
                </ul>
            </div>
            <hr/>
            { !actor && <ActorSearch selectActor={selectActor}/>}
            { actor && <MovieSearch selectMovie={(a) => {
                setMovies([...movies, a]);
            }}/> }
        </div>

    );
};

;
//export default ActorsMoviesGame;
export {CreditsGame};
