import React, {useState} from "react";
import _ from "lodash";
import * as PropTypes from "prop-types";
import {headshot, poster, ImageWithLoading} from "./Util"; // Import the Spinner component
import './App.css';
import {Actor} from "./Actor.js";
import {Movie, MovieButton} from "./Movie.js";

MovieSearch.propTypes = {
    selectMovie: PropTypes.func
};

export function MovieSearch(props) {
    const [movies, setActors] = useState([]); // [ {name: 'foo', id: 1}, {name: 'bar', id: 2}
    const [latestRequest, setLatestRequest] = useState(0);
    const [latestAcceptedRequest, setLatestAcceptedRequest] = useState(0);
    const [inputValue, setInputValue] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 10; // Number of items you want to display per page

    // Calculate the indices of the first and last items on the current pag
    const firstItemIndex = currentPage * itemsPerPage;
    const lastItemIndex = firstItemIndex + itemsPerPage;

    // Handlers for pagination
    const goToNextPage = () => {
        setCurrentPage((prevCurrentPage) => prevCurrentPage + 1);
    };

    const goToPreviousPage = () => {
        setCurrentPage((prevCurrentPage) => Math.max(prevCurrentPage - 1, 0));
    };
    const isPreviousPageDisabled = currentPage === 0;
    const isNextPageDisabled = lastItemIndex >= movies.length;
    const searchActors = async (val) => {
        const currentRequest = Date.now();
        if (!val) {

            setActors([]);
            setLatestAcceptedRequest((prevLatestAccepted) => {
                return currentRequest;
            })
            return;
        }

        setLatestRequest(currentRequest);

        console.log(`Fetching ${val}`);
        let response = await fetch(`/api/get_movies_by_name/${val}`);
        if (!response.ok) {
            console.log("Error fetching movies");
            if (currentRequest === latestRequest) {
                setActors([]);
            }
            return;
        }
        let foo = await response.json();
        setLatestAcceptedRequest((prevLatestAccepted) => {
            if (currentRequest >= prevLatestAccepted) {
                setActors(foo);
                return currentRequest;
            }
            return prevLatestAccepted;
        });
        console.log(movies);
    };

    const debouncedSearchActors = (_.debounce(searchActors, 150));
    const handleInputChange = (e) => {
        setInputValue(e.target.value);
        setCurrentPage(0);
        if (inputValue) {
            debouncedSearchActors(e.target.value);
        }
    }

    return <div className="">
        <h2>Search: {inputValue}</h2>
        <span>🔎<input type="text" placeholder="Search for a movie" value={inputValue}
                      onChange={handleInputChange}/></span>
        <div id="movieListPanel">
            <ul className="actorlist">
                {movies.slice(firstItemIndex, lastItemIndex).map((a) => {
                    return <li className="inline_img" key={a.id}>
                <MovieButton className="thingy" movie={a} selectMovie={props.selectMovie}></MovieButton>
                        {/*<button title={`Select ${a.name} as one of the movies`} onClick={() => props.selectMovie(a)} className="thingy"><ImageWithLoading*/}
                        {/*    className='smallimg' alt={`A movie poster of ${a.name}`} src={poster(a)}></ImageWithLoading>*/}
                        {/*    <div>{a.name}</div>*/}
                        {/*</button>*/}
                    </li>;
                })}
            </ul>
        </div>
        <div className="pagination-buttons">
            <button onClick={goToPreviousPage} disabled={isPreviousPageDisabled}>Previous</button>
            <button onClick={goToNextPage} disabled={isNextPageDisabled}>Next</button>
        </div>
    </div>;
}