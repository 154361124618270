import {poster} from "./Util";
import * as PropTypes from "prop-types";
import React from "react";

function Movie(props) {
    return <>
        <img
            className="medimg"
            src={poster(props.movie)}
            alt={`A movie poster from the movie ${props.movie.name}`}
        />
        <div>{props.movie.name}</div>
    </>;
}
function MovieButton(props) {
    return <button title={`Select ${props.movie.name} as one of the movies`} onClick={() => props.selectMovie(props.movie)} className="thingy"><Movie movie={props.movie}></Movie></button>;
}

Movie.propTypes = {movie: PropTypes.any};
export default Movie;
export {MovieButton, Movie};